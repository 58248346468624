// vue
import { h } from 'vue'

// vuetify
import type { IconSet, IconProps } from 'vuetify'

// avatar icons
import AccessoriesKurt from '../components/icons/avataaar/AccessoriesKurt.vue'
import AccessoriesPrescription01 from '../components/icons/avataaar/AccessoriesPrescription01.vue'
import AccessoriesPrescription02 from '../components/icons/avataaar/AccessoriesPrescription02.vue'
import AccessoriesRound from '../components/icons/avataaar/AccessoriesRound.vue'
import AccessoriesSunglasses from '../components/icons/avataaar/AccessoriesSunglasses.vue'
import AccessoriesWayfarers from '../components/icons/avataaar/AccessoriesWayfarers.vue'
import AvatarBackground from '../components/icons/avataaar/AvatarBackground.vue'
import AvatarSilhouette from '../components/icons/avataaar/AvatarSilhouette.vue'
import ClothesBlazerShirt from '../components/icons/avataaar/ClothesBlazerShirt.vue'
import ClothesBlazerSweater from '../components/icons/avataaar/ClothesBlazerSweater.vue'
import ClothesCollarSweater from '../components/icons/avataaar/ClothesCollarSweater.vue'
import ClothesGraphicShirt from '../components/icons/avataaar/ClothesGraphicShirt.vue'
import ClothesHoodie from '../components/icons/avataaar/ClothesHoodie.vue'
import ClothesOverall from '../components/icons/avataaar/ClothesOverall.vue'
import ClothesShirtCrewNeck from '../components/icons/avataaar/ClothesShirtCrewNeck.vue'
import ClothesShirtScoopNeck from '../components/icons/avataaar/ClothesShirtScoopNeck.vue'
import ClothesShirtVNeck from '../components/icons/avataaar/ClothesShirtVNeck.vue'
import EyebrowsAngry from '../components/icons/avataaar/EyebrowsAngry.vue'
import EyebrowsAngryNatural from '../components/icons/avataaar/EyebrowsAngryNatural.vue'
import EyebrowsDefault from '../components/icons/avataaar/EyebrowsDefault.vue'
import EyebrowsDefaultNatural from '../components/icons/avataaar/EyebrowsDefaultNatural.vue'
import EyebrowsFlatNatural from '../components/icons/avataaar/EyebrowsFlatNatural.vue'
import EyebrowsFrownNatural from '../components/icons/avataaar/EyebrowsFrownNatural.vue'
import EyebrowsRaisedExcited from '../components/icons/avataaar/EyebrowsRaisedExcited.vue'
import EyebrowsRaisedExcitedNatural from '../components/icons/avataaar/EyebrowsRaisedExcitedNatural.vue'
import EyebrowsSadConcerned from '../components/icons/avataaar/EyebrowsSadConcerned.vue'
import EyebrowsSadConcernedNatural from '../components/icons/avataaar/EyebrowsSadConcernedNatural.vue'
import EyebrowsUnibrowNatural from '../components/icons/avataaar/EyebrowsUnibrowNatural.vue'
import EyebrowsUpDown from '../components/icons/avataaar/EyebrowsUpDown.vue'
import EyebrowsUpDownNatural from '../components/icons/avataaar/EyebrowsUpDownNatural.vue'
import EyesClose from '../components/icons/avataaar/EyesClose.vue'
import EyesCry from '../components/icons/avataaar/EyesCry.vue'
import EyesDefault from '../components/icons/avataaar/EyesDefault.vue'
import EyesDizzy from '../components/icons/avataaar/EyesDizzy.vue'
import EyesEyeRoll from '../components/icons/avataaar/EyesEyeRoll.vue'
import EyesHappy from '../components/icons/avataaar/EyesHappy.vue'
import EyesHearts from '../components/icons/avataaar/EyesHearts.vue'
import EyesSide from '../components/icons/avataaar/EyesSide.vue'
import EyesSquint from '../components/icons/avataaar/EyesSquint.vue'
import EyesSurprised from '../components/icons/avataaar/EyesSurprised.vue'
import EyesWink from '../components/icons/avataaar/EyesWink.vue'
import EyesWinkWacky from '../components/icons/avataaar/EyesWinkWacky.vue'
import FacialHairBeardMedium from '../components/icons/avataaar/FacialHairBeardMedium.vue'
import FacialHairBeardLight from '../components/icons/avataaar/FacialHairBeardLight.vue'
import FacialHairBeardMajestic from '../components/icons/avataaar/FacialHairBeardMajestic.vue'
import FacialHairMoustacheFancy from '../components/icons/avataaar/FacialHairMoustacheFancy.vue'
import FacialHairMoustacheMagnum from '../components/icons/avataaar/FacialHairMoustacheMagnum.vue'
import GraphicSkull from '../components/icons/avataaar/GraphicSkull.vue'
import GraphicSkullOutline from '../components/icons/avataaar/GraphicSkullOutline.vue'
import GraphicBat from '../components/icons/avataaar/GraphicBat.vue'
import GraphicCumbia from '../components/icons/avataaar/GraphicCumbia.vue'
import GraphicDeer from '../components/icons/avataaar/GraphicDeer.vue'
import GraphicHola from '../components/icons/avataaar/GraphicHola.vue'
import GraphicSelena from '../components/icons/avataaar/GraphicSelena.vue'
import GraphicDiamond from '../components/icons/avataaar/GraphicDiamond.vue'
import GraphicPizza from '../components/icons/avataaar/GraphicPizza.vue'
import GraphicResist from '../components/icons/avataaar/GraphicResist.vue'
import GraphicBear from '../components/icons/avataaar/GraphicBear.vue'
import MouthConcerned from '../components/icons/avataaar/MouthConcerned.vue'
import MouthDefault from '../components/icons/avataaar/MouthDefault.vue'
import MouthDisbelief from '../components/icons/avataaar/MouthDisbelief.vue'
import MouthEating from '../components/icons/avataaar/MouthEating.vue'
import MouthGrimace from '../components/icons/avataaar/MouthGrimace.vue'
import MouthSad from '../components/icons/avataaar/MouthSad.vue'
import MouthScreamOpen from '../components/icons/avataaar/MouthScreamOpen.vue'
import MouthSerious from '../components/icons/avataaar/MouthSerious.vue'
import MouthSmile from '../components/icons/avataaar/MouthSmile.vue'
import MouthTongue from '../components/icons/avataaar/MouthTongue.vue'
import MouthTwinkle from '../components/icons/avataaar/MouthTwinkle.vue'
import MouthVomit from '../components/icons/avataaar/MouthVomit.vue'
import TopEyepatch from '../components/icons/avataaar/TopEyepatch.vue'
import TopHat from '../components/icons/avataaar/TopHat.vue'
import TopHijab from '../components/icons/avataaar/TopHijab.vue'
import TopTurban from '../components/icons/avataaar/TopTurban.vue'
import TopWinterHat1 from '../components/icons/avataaar/TopWinterHat1.vue'
import TopWinterHat2 from '../components/icons/avataaar/TopWinterHat2.vue'
import TopWinterHat3 from '../components/icons/avataaar/TopWinterHat3.vue'
import TopWinterHat4 from '../components/icons/avataaar/TopWinterHat4.vue'
import TopWinterHat5 from '../components/icons/avataaar/TopWinterHat5.vue'
import TopLongHairBigHair from '../components/icons/avataaar/TopLongHairBigHair.vue'
import TopLongHairBob from '../components/icons/avataaar/TopLongHairBob.vue'
import TopLongHairBun from '../components/icons/avataaar/TopLongHairBun.vue'
import TopLongHairCurly from '../components/icons/avataaar/TopLongHairCurly.vue'
import TopLongHairCurvy from '../components/icons/avataaar/TopLongHairCurvy.vue'
import TopLongHairDreads from '../components/icons/avataaar/TopLongHairDreads.vue'
import TopLongHairFrida from '../components/icons/avataaar/TopLongHairFrida.vue'
import TopLongHairFro from '../components/icons/avataaar/TopLongHairFro.vue'
import TopLongHairFroBand from '../components/icons/avataaar/TopLongHairFroBand.vue'
import TopLongHairNotTooLong from '../components/icons/avataaar/TopLongHairNotTooLong.vue'
import TopLongHairShavedSides from '../components/icons/avataaar/TopLongHairShavedSides.vue'
import TopLongHairMiaWallace from '../components/icons/avataaar/TopLongHairMiaWallace.vue'
import TopLongHairStraight from '../components/icons/avataaar/TopLongHairStraight.vue'
import TopLongHairStraight2 from '../components/icons/avataaar/TopLongHairStraight2.vue'
import TopLongHairStraightStrand from '../components/icons/avataaar/TopLongHairStraightStrand.vue'
import TopShortHairDreads01 from '../components/icons/avataaar/TopShortHairDreads01.vue'
import TopShortHairDreads02 from '../components/icons/avataaar/TopShortHairDreads02.vue'
import TopShortHairFrizzle from '../components/icons/avataaar/TopShortHairFrizzle.vue'
import TopShortHairShaggy from '../components/icons/avataaar/TopShortHairShaggy.vue'
import TopShortHairShaggyMullet from '../components/icons/avataaar/TopShortHairShaggyMullet.vue'
import TopShortHairShortCurly from '../components/icons/avataaar/TopShortHairShortCurly.vue'
import TopShortHairShortFlat from '../components/icons/avataaar/TopShortHairShortFlat.vue'
import TopShortHairShortRound from '../components/icons/avataaar/TopShortHairShortRound.vue'
import TopShortHairShortWaved from '../components/icons/avataaar/TopShortHairShortWaved.vue'
import TopShortHairSides from '../components/icons/avataaar/TopShortHairSides.vue'
import TopShortHairTheCaesar from '../components/icons/avataaar/TopShortHairTheCaesar.vue'
import TopShortHairTheCaesarSidePart from '../components/icons/avataaar/TopShortHairTheCaesarSidePart.vue'

// chevron icons
import ChevronDown from '../components/icons/chevron/ChevronDown.vue'

// contact
import Contact from '../components/icons/contact/Contact.vue'
import Mail from '../components/icons/contact/Mail.vue'
import Phone from '../components/icons/contact/Phone.vue'

// credit card icons
import CreditCardAmex from '../components/icons/credit-card/CreditCardAmex.vue'
import CreditCardDinersClub from '../components/icons/credit-card/CreditCardDinersClub.vue'
import CreditCardDiscover from '../components/icons/credit-card/CreditCardDiscover.vue'
import CreditCardJCB from '../components/icons/credit-card/CreditCardJCB.vue'
import CreditCardMasterCard from '../components/icons/credit-card/CreditCardMasterCard.vue'
import CreditCardRegular from '../components/icons/credit-card/CreditCardRegular.vue'
import CreditCardSolid from '../components/icons/credit-card/CreditCardSolid.vue'
import CreditCardVisa from '../components/icons/credit-card/CreditCardVisa.vue'

// navigation drawer icons
import ArchiveBox from '../components/icons/navigation-drawer/ArchiveBox.vue'
import Book from '../components/icons/navigation-drawer/Book.vue'
import CalendarCheck from '../components/icons/navigation-drawer/CalendarCheck.vue'
import CalendarDays from '../components/icons/navigation-drawer/CalendarDays.vue'
import Home from '../components/icons/navigation-drawer/Home.vue'
import Paper from '../components/icons/navigation-drawer/Paper.vue'
import VideoPlay from '../components/icons/navigation-drawer/VideoPlay.vue'

// file icons
import File from '../components/icons/file/File.vue'
import FileAlt from '../components/icons/file/FileAlt.vue'

// math icons
import Equation from '../components/icons/math/Equation.vue'
import Plus from '../components/icons/math/Plus.vue'

// miscellaneous icons
import Bookmark from '../components/icons/misc/Bookmark.vue'
import ExclamationMark from '../components/icons/misc/ExclamationMark.vue'
import Info from '../components/icons/misc/Info.vue'
import InfoFilled from '../components/icons/misc/InfoFilled.vue'
import Mortarboard from '../components/icons/misc/Mortarboard.vue'
import Pencil from '../components/icons/misc/Pencil.vue'

// session icons
import UserSession from '../components/icons/session/UserSession.vue'

const aliases = {
  // avatar icons
  AccessoriesKurt,
  AccessoriesPrescription01,
  AccessoriesPrescription02,
  AccessoriesRound,
  AccessoriesSunglasses,
  AccessoriesWayfarers,
  AvatarBackground,
  AvatarSilhouette,
  ClothesBlazerShirt,
  ClothesBlazerSweater,
  ClothesCollarSweater,
  ClothesGraphicShirt,
  ClothesHoodie,
  ClothesOverall,
  ClothesShirtCrewNeck,
  ClothesShirtScoopNeck,
  ClothesShirtVNeck,
  EyebrowsAngry,
  EyebrowsAngryNatural,
  EyebrowsDefault,
  EyebrowsDefaultNatural,
  EyebrowsFlatNatural,
  EyebrowsFrownNatural,
  EyebrowsRaisedExcited,
  EyebrowsRaisedExcitedNatural,
  EyebrowsSadConcerned,
  EyebrowsSadConcernedNatural,
  EyebrowsUnibrowNatural,
  EyebrowsUpDown,
  EyebrowsUpDownNatural,
  EyesClose,
  EyesCry,
  EyesDefault,
  EyesDizzy,
  EyesEyeRoll,
  EyesHappy,
  EyesHearts,
  EyesSide,
  EyesSquint,
  EyesSurprised,
  EyesWink,
  EyesWinkWacky,
  FacialHairBeardMedium,
  FacialHairBeardLight,
  FacialHairBeardMajestic,
  FacialHairMoustacheFancy,
  FacialHairMoustacheMagnum,
  GraphicSkull,
  GraphicSkullOutline,
  GraphicBat,
  GraphicCumbia,
  GraphicDeer,
  GraphicHola,
  GraphicSelena,
  GraphicDiamond,
  GraphicPizza,
  GraphicResist,
  GraphicBear,
  MouthConcerned,
  MouthDefault,
  MouthDisbelief,
  MouthEating,
  MouthGrimace,
  MouthSad,
  MouthScreamOpen,
  MouthSerious,
  MouthSmile,
  MouthTongue,
  MouthTwinkle,
  MouthVomit,
  TopEyepatch,
  TopHat,
  TopHijab,
  TopTurban,
  TopWinterHat1,
  TopWinterHat2,
  TopWinterHat3,
  TopWinterHat4,
  TopWinterHat5,
  TopLongHairBigHair,
  TopLongHairBob,
  TopLongHairBun,
  TopLongHairCurly,
  TopLongHairCurvy,
  TopLongHairDreads,
  TopLongHairFrida,
  TopLongHairFro,
  TopLongHairFroBand,
  TopLongHairNotTooLong,
  TopLongHairShavedSides,
  TopLongHairMiaWallace,
  TopLongHairStraight,
  TopLongHairStraight2,
  TopLongHairStraightStrand,
  TopShortHairDreads01,
  TopShortHairDreads02,
  TopShortHairFrizzle,
  TopShortHairShaggy,
  TopShortHairShaggyMullet,
  TopShortHairShortCurly,
  TopShortHairShortFlat,
  TopShortHairShortRound,
  TopShortHairShortWaved,
  TopShortHairSides,
  TopShortHairTheCaesar,
  TopShortHairTheCaesarSidePart,
  // chevron icons
  ChevronDown,
  // contact icons
  Contact,
  Mail,
  Phone,
  // credit card icons
  CreditCardAmex,
  CreditCardDinersClub,
  CreditCardDiscover,
  CreditCardJCB,
  CreditCardMasterCard,
  CreditCardRegular,
  CreditCardSolid,
  CreditCardVisa,
  // navigation drawer icons
  ArchiveBox,
  Book,
  CalendarCheck,
  CalendarDays,
  Home,
  Paper,
  VideoPlay,
  // file icons
  File,
  FileAlt,
  // math icons
  Equation,
  Plus,
  // miscellaneous icons
  Bookmark,
  ExclamationMark,
  Info,
  InfoFilled,
  Mortarboard,
  Pencil,
  // session icons
  UserSession
}

const custom: IconSet = {
  component: (props: IconProps) => {
    return h(aliases[props.icon as keyof typeof aliases])
  }
}

export { aliases, custom }
